import {AbstractApi} from '@/service/api/AbstractApi'
import {DeliveryNoteApi} from '@/service/api/DeliveryNoteApi'
import {deliveryNoteArraySchema, DeliveryNoteBean, deliveryNoteSchema} from '@/service/model/DeliveryNoteBean'
import {I18n} from 'vue-i18n'
import {LocationBean} from '@/service/model/LocationBean'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {SynchronizationService, SynchronizeResult} from '@/service/SynchronizationService'
import {SiteManagerConfirmFlow} from '@/stores/modules/SiteManagerDeliveryNoteConfirmModule'

export interface ConfirmLoadBean {
  location?: LocationBean
  day?: string | null // ex: 2023-09-27
  date: string // ex: 2023-09-27T10:16:11+02:00
  truck_registration?: string | null
  comment?: string
}

export interface ConfirmUnloadBean {
  location?: LocationBean
  day?: string | null // ex: 2023-09-27
  date: string // ex: 2023-09-27T10:16:11+02:00
}

export interface ConfirmUnloadWithPictureBean {
  location?: LocationBean
  day?: string | null // ex: 2023-09-27
  date: string // ex: 2023-09-27T10:16:11+02:00
  net_weight?: number | null
  truck_registration?: string | null
  comment?: string
}

export interface CreatedDeliveryNoteBean {
  order_id: string
  count: number
  material_type: string
  with_backload: boolean
  driver_phone_number?: string | null
  truck_registration?: string | null
  comment?: string | null
}

export class SiteManagerDeliveryNoteApi extends AbstractApi
  implements DeliveryNoteApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    private readonly synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService)
  }
  
  fetchDeliveryNote(id: string): Promise<DeliveryNoteBean | null> {
    return this.get<DeliveryNoteBean>({
      path: `/api/mp/v1/site-manager/delivery-notes/${id}`,
      authentication: 'required',
      schema: deliveryNoteSchema
    })
  }
  
  fetchDeliveryNoteByCode(siteManagerCode: string): Promise<DeliveryNoteBean | null> {
    return this.get<DeliveryNoteBean>({
      path: `/api/mp/v1/site-manager/delivery-notes/by-code/${siteManagerCode}`,
      authentication: 'required',
      schema: deliveryNoteSchema
    })
  }
  
  fetchUpcomingDeliveryNotes(): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/site-manager/delivery-notes/upcoming',
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }
  
  fetchSimilarDeliveryNotes(deliveryNoteId:string): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/site-manager/delivery-notes/similar/' + deliveryNoteId,
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }
  fetchDeliveredDeliveryNotes(): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/site-manager/delivery-notes/delivered',
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }
  
  confirmLoad(
    confirmFlow: SiteManagerConfirmFlow,
    id: string,
    body: ConfirmLoadBean
  ): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/site-manager/delivery-notes/${id}/confirm-load`
    return this.synchronizationService.callOrSynchronize(
      {
        type: confirmFlow,
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: body,
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: body
        })
      }
    )
  }
  
  confirmUnload(
    confirmFlow: SiteManagerConfirmFlow,
    id: string,
    body: ConfirmUnloadBean
  ): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/site-manager/delivery-notes/${id}/confirm-unload`
    return this.synchronizationService.callOrSynchronize(
      {
        type: confirmFlow,
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: body,
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: body
        })
      }
    )
  }
  
  confirmUnloadWithPicture(
    confirmFlow: string,
    id: string,
    body: ConfirmUnloadWithPictureBean
  ): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/site-manager/delivery-notes/${id}/confirm-unload-with-picture`
    return this.synchronizationService.callOrSynchronize(
      {
        type: confirmFlow,
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: body,
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: body
        })
      }
    )
  }
  
  async updateComments(id: string, comments: string): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/site-manager/delivery-notes/${id}/comment`
    return this.synchronizationService.callOrSynchronize(
      {
        type: 'COMMENT',
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: {comment: comments},
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: {comment: comments}
        })
      }
    )
  }
  
  async create(body: CreatedDeliveryNoteBean): Promise<Array<DeliveryNoteBean>> {
    return this.post<Array<DeliveryNoteBean>>({
      path: '/api/mp/v2/site-manager/delivery-notes',
      authentication: 'required',
      schema: deliveryNoteArraySchema,
      body: body
    })
  }
  
  async deleteDeliveryNote(id: string): Promise<void> {
    return this.deleteVoid({
      path: `/api/mp/v1/site-manager/delivery-notes/${id}`,
      authentication: 'required'
    })
  }
}
