import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu_divider = _resolveComponent("main-menu-divider")!
  const _component_main_menu_item = _resolveComponent("main-menu-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('quotation.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('quotation.list'),
      to: {name: 'SiteManagerQuotationRequests'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('quotation.add'),
      onItemClick: _ctx.onQuotationRequestClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('order.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('order.list'),
      to: {name: 'SiteManagerOrders'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('order.addExpressOrder'),
      onItemClick: _ctx.onCreateExpressOrderClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('deliveryRequest.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.accepted'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'accepted'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.inProgress'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'pending'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.refused'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'rejected'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.expired'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'expired'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.delivered'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'delivered'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryRequest.disputes'),
      to: { name: 'SiteManagerDeliveryRequestList', params: { status: 'disputed'} }
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('order.deliveryRequest'),
      onItemClick: _ctx.onDeliveryRequestClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('deliveryNote.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.upcoming'),
      to: {name: _ctx.SiteManagerDeliveryNotesRoute.UPCOMING}
    }, null, 8, ["label", "to"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('deliveryNote.delivered'),
      to: {name: _ctx.SiteManagerDeliveryNotesRoute.DELIVERED}
    }, null, 8, ["label", "to"]),
    _createVNode(_component_main_menu_item, {
      icon: _ctx.add,
      label: _ctx.t('deliveryNote.addPickupDeliveryNote'),
      onItemClick: _ctx.onCreatePickupDeliveryNoteButtonClicked
    }, null, 8, ["icon", "label", "onItemClick"]),
    _createVNode(_component_main_menu_divider, {
      label: _ctx.t('config.title')
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('config.drivers'),
      to: {name: 'SiteManagerDrivers'}
    }, null, 8, ["label"]),
    _createVNode(_component_main_menu_item, {
      label: _ctx.t('config.truckRegistrations'),
      to: {name: 'SiteManagerTrucks'}
    }, null, 8, ["label"])
  ], 64))
}