import {DeliveryNoteApi} from '@/service/api/DeliveryNoteApi'
import {ActionContext, Module} from 'vuex'
import {AppState} from '@/stores'
import {LocalStore} from '@/service/store/LocalStore'
import Result from '@/service/operation/Result'
import {GetOperation} from '@/service/operation/GetOperation'
import {DeliveryNoteBean} from '@/service/model/DeliveryNoteBean'
import {DriverDeliveryNoteApi} from '@/service/api/driver/DriverDeliveryNoteApi'
import {SiteManagerDeliveryNoteApi} from '@/service/api/sitemanager/SiteManagerDeliveryNoteApi'
import {ROLE_DRIVER, ROLE_SITE_MANAGER} from '@/service/api/CurrentUserApi'
import {makeException} from '@/exception/Exception'
import {I18n} from 'vue-i18n'
import {PushNotificationService} from '@/service/PushNotificationService'
import {PushNotificationEvent} from '@/service/model/PushNotificationEvent'
import {
  DeliveryNoteStatusChangedBean,
  deliveryNoteStatusChangedSchema
} from '@/service/model/DeliveryNoteStatusChangedBean'
import {Subscription} from '@/service/subscription/Subscription'
import {DeliveryNoteSyncService} from '@/service/DeliveryNoteSyncService'
import {translateMessage} from '@/i18n'
import {
  getAndTransformItemOperation,
  getAndTransformListOperation,
  GetOptions
} from '@/service/operation/GetAndTransformOperation'

const upcomingDeliveryNoteStoreKey = 'setDeliveryNotes'

export interface DeliveryNoteState {
  pushNotificationSubscription?: Subscription
  upcomingDeliveryNotesOperation?: GetOperation<Array<DeliveryNoteBean>, string>
  upcomingDeliveryNotes?: Result<Array<DeliveryNoteBean>>
  pendingDeliveryNoteIds?: Array<string>
  deliveredDeliveryNotes?: Array<DeliveryNoteBean> | null
  currentDeliveryNoteOperation?: GetOperation<DeliveryNoteBean, string>
  currentDeliveryNote?: Result<DeliveryNoteBean>
}

export class DeliveryNoteModule implements Module<DeliveryNoteState, AppState> {
  state = {}
  mutations = {
    setPushNotificationSubscription: (state, subscription) => state.pushNotificationSubscription = subscription,
    setUpcomingDeliveryNotesOperation: (state, operation) => state.upcomingDeliveryNotesOperation = operation,
    setUpcomingDeliveryNotes: (state, upcomingDeliveryNotes) => state.upcomingDeliveryNotes = upcomingDeliveryNotes,
    setPendingDeliveryNoteIds: (state, pendingDeliveryNoteIds) => state.pendingDeliveryNoteIds = pendingDeliveryNoteIds,
    setDeliveredDeliveryNotes: (state, deliveryDeliveryNotes) => state.deliveredDeliveryNotes = deliveryDeliveryNotes,
    setCurrentDeliveryNoteOperation: (state, operation) => state.currentDeliveryNoteOperation = operation,
    setCurrentDeliveryNote: (state, currentDeliveryNote) => state.currentDeliveryNote = currentDeliveryNote
  }
  getters = {
    upcomingDeliveryNotes: (state): Result<DeliveryNoteBean> | undefined => state.upcomingDeliveryNotes,
    pendingDeliveryNoteIds: (state) => state.pendingDeliveryNoteIds,
    deliveredDeliveryNotes: (state): Array<DeliveryNoteBean> | null | undefined => state.deliveredDeliveryNotes,
    currentDeliveryNote: (state): Result<DeliveryNoteBean> | undefined => state.currentDeliveryNote
  }
  actions = {
    registerForPushNotification: this.registerForPushNotificationAction.bind(this),
    fetchUpcomingDeliveryNotes: this.fetchUpcomingDeliveryNotesAction.bind(this),
    fetchUpcomingSimilarDeliveryNotes: this.fetchUpcomingSimilarDeliveryNotesAction.bind(this),
    refreshUpcomingDeliveryNotes: this.refreshUpcomingDeliveryNotesAction.bind(this),
    fetchPendingDeliveryNoteIds: this.fetchPendingDeliveryNoteIdsAction.bind(this),
    fetchDeliveredDeliveryNotes: this.fetchDeliveredDeliveryNotesAction.bind(this),
    fetchDeliveredSimilarDeliveryNotes: this.fetchDeliveredSimilarDeliveryNotesAction.bind(this),
    selectCurrentDeliveryNote: this.selectCurrentDeliveryNoteAction.bind(this),
    attachDeliveryNotes: this.attachDeliveryNotesAction.bind(this),
    addUpcomingDeliveryNotes: this.addUpcomingDeliveryNotesAction.bind(this),
    clearDeliveryNoteModule: this.clearDeliveryNoteModuleAction.bind(this),
    updateComments: this.updateCommentsAction.bind(this),
    fetchDeliveryNoteByCode: this.fetchDeliveryNoteByCodeAction.bind(this),
    deleteDeliveryNote: this.deleteDeliveryNoteAction.bind(this)
  }
  private readonly deliveryNoteListStore = new LocalStore<Array<string>, string>({
    localStorageKey: 'delivery-note-lists'
  })
  
  constructor(
    private readonly i18n: I18n,
    private readonly deliveryNoteStore: LocalStore<DeliveryNoteBean, string>,
    private readonly driverDeliveryNoteApi: DriverDeliveryNoteApi,
    private readonly siteManagerDeliveryNoteApi: SiteManagerDeliveryNoteApi,
    private readonly deliveryNoteSyncService: DeliveryNoteSyncService,
    private readonly pushNotificationService: PushNotificationService
  ) {
  }
  
  private getDeliveryNoteApi(context: ActionContext<DeliveryNoteState, AppState>): DeliveryNoteApi {
    const role = context.rootGetters.selectedRole
    switch (role) {
    case ROLE_DRIVER:
      return this.driverDeliveryNoteApi
    case ROLE_SITE_MANAGER:
      return this.siteManagerDeliveryNoteApi
    default:
      console.error('Selected role \'%o\' does not allow to select delivery note API.')
      throw makeException(this.i18n, 'api.unknown')
    }
  }
  
  private async fetchUpcomingDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: GetOptions<any>
  ): Promise<Array<DeliveryNoteBean> | null> {
    await context.dispatch('registerForPushNotification')
    
    const role = context.rootGetters.selectedRole
    const operation = getAndTransformListOperation(
      this.deliveryNoteStore,
      this.deliveryNoteListStore,
      upcomingDeliveryNoteStoreKey,
      it => it.id,
      () => this.getDeliveryNoteApi(context).fetchUpcomingDeliveryNotes(),
      async (it) => this.deliveryNoteSyncService.syncArrayWithPending(role, it)
    )
    
    context.commit('setUpcomingDeliveryNotesOperation', operation)
    
    return await operation.get(
      params,
      async (it) => {
        context.commit('setUpcomingDeliveryNotes', it)
        
        const itemFromNetwork = it.itemFromNetwork
        if (itemFromNetwork) {
          await context.dispatch('load/updateDeliveryNoteFromUpcoming', itemFromNetwork)
          await context.dispatch('unload/updateDeliveryNoteFromUpcoming', itemFromNetwork)
        }
      }
    )
  }
  
  private async fetchUpcomingSimilarDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: {options: GetOptions<any>; deliveryNoteId: string},
  ): Promise<Array<DeliveryNoteBean> | null> {
    const newDeliveryNotes = await this.siteManagerDeliveryNoteApi.fetchSimilarDeliveryNotes(params.deliveryNoteId)
    await context.dispatch('addUpcomingDeliveryNotes', newDeliveryNotes)
    return newDeliveryNotes
  }
  private async refreshUpcomingDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ): Promise<Array<DeliveryNoteBean> | null> {
    const operation = context.state.upcomingDeliveryNotesOperation
    if (!operation) {
      throw makeException(this.i18n, 'error.unknown')
    }
    return operation.refresh(true)
  }
  
  private async fetchPendingDeliveryNoteIdsAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ): Promise<Array<string>> {
    const ids = await this.deliveryNoteSyncService.getPendingSyncs()
    context.commit('setPendingDeliveryNoteIds', ids)
    return ids
  }
  
  private async fetchDeliveredDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ): Promise<Array<DeliveryNoteBean> | null> {
    const deliveryNotes = await this.getDeliveryNoteApi(context).fetchDeliveredDeliveryNotes()
    context.commit('setDeliveredDeliveryNotes', deliveryNotes)
    return deliveryNotes
  }
  
  private async fetchDeliveredSimilarDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: {options: GetOptions<any>; deliveryNoteId: string},
  ): Promise<Array<DeliveryNoteBean> | null> {
    const newDeliveryNotes = await this.siteManagerDeliveryNoteApi.fetchSimilarDeliveryNotes(params.deliveryNoteId)
    const deliveryNotes = context.state.deliveredDeliveryNotes || []
    context.commit('setDeliveredDeliveryNotes', deliveryNotes.concat(newDeliveryNotes))
    return newDeliveryNotes
  }
  private async selectCurrentDeliveryNoteAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: GetOptions<string>
  ): Promise<DeliveryNoteBean | null> {
    await context.dispatch('registerForPushNotification')
    
    const role = context.rootGetters.selectedRole
    const operation = getAndTransformItemOperation(
      this.deliveryNoteStore,
      id => this.getDeliveryNoteApi(context).fetchDeliveryNote(id),
      async (it) => await this.deliveryNoteSyncService.syncWithPending(role, it)
    )
    
    context.commit('setCurrentDeliveryNoteOperation', operation)
    context.commit('setCurrentDeliveryNote', undefined)
    
    return await operation.get(
      params,
      it => context.commit('setCurrentDeliveryNote', it)
    )
  }
  
  private registerForPushNotificationAction(
    context: ActionContext<DeliveryNoteState, AppState>
  ) {
    if (context.state.pushNotificationSubscription) {
      return
    }
    const callback = this.onDeliveryNoteStatusChanged.bind(this, context)
    const subscription = this.pushNotificationService.registerForEventReceived({
      event: PushNotificationEvent.DELIVERY_NOTE_STATUS_CHANGED,
      schema: deliveryNoteStatusChangedSchema,
      callback
    })
    context.commit('setPushNotificationSubscription', subscription)
  }
  
  // noinspection JSUnusedLocalSymbols
  private onDeliveryNoteStatusChanged(
    context: ActionContext<DeliveryNoteState, AppState>,
    event: string,
    data: DeliveryNoteStatusChangedBean
  ) {
    this.updateCurrentDeliveryNoteIfStatusChanged(context.state, data)
    this.updateUpcomingDeliveryNotesIfStatusChanged(context.state, data)
  }
  
  private updateCurrentDeliveryNoteIfStatusChanged(
    state: DeliveryNoteState,
    data: DeliveryNoteStatusChangedBean
  ) {
    const operation = state.currentDeliveryNoteOperation
    const result = state.currentDeliveryNote
    if (!operation || !result) {
      return
    }
    // We do not try to refresh if we do not have a response from the network.
    if (!result.itemFromNetwork) {
      return
    }
    // Only refresh if the selected delivery note is the one in the notification.
    if (result.itemFromNetwork.id !== data.delivery_note_id) {
      return
    }
    console.info('Push notification triggered delivery note update.')
    operation.refresh(false).catch(error => console.error('Failed to refresh delivery note after push notification.', error))
  }
  
  private updateUpcomingDeliveryNotesIfStatusChanged(
    state: DeliveryNoteState,
    data: DeliveryNoteStatusChangedBean
  ) {
    const operation = state.upcomingDeliveryNotesOperation
    const result = state.upcomingDeliveryNotes
    if (!operation || !result) {
      return
    }
    // We do not try to refresh if we do not have a response from the network.
    if (!result.itemFromNetwork) {
      return
    }
    // Only refresh if the delivery note in the notification is in the list.
    const deliveryNote = result.itemFromNetwork.find(it => it.id === data.delivery_note_id)
    if (!deliveryNote) {
      return
    }
    if (deliveryNote.status === data.status) {
      return
    }
    console.info('Push notification triggered upcoming delivery notes update.')
    operation.refresh(false).catch(error => console.error('Failed to refresh upcoming delivery notes after push notification', error))
  }
  
  private async attachDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    params: { deliveryOrOrderCode: string }
  ): Promise<Array<DeliveryNoteBean>> {
    const newDeliveryNotes = await this.driverDeliveryNoteApi.attachDeliveryNotes(params.deliveryOrOrderCode)
    await context.dispatch('addUpcomingDeliveryNotes', newDeliveryNotes)
    return newDeliveryNotes
  }
  
  private async addUpcomingDeliveryNotesAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    deliveryNotes: Array<DeliveryNoteBean>
  ): Promise<void> {
    if (deliveryNotes.length == 0) {
      return
    }
    
    // Put new delivery notes in cache.
    for (const deliveryNote of deliveryNotes) {
      this.deliveryNoteStore.saveItem(deliveryNote.id, deliveryNote)
    }
    
    // Add them to the list of upcoming
    const ids = this.deliveryNoteListStore.getItem(upcomingDeliveryNoteStoreKey) || []
    ids.push(...deliveryNotes.map(it => it.id))
    this.deliveryNoteListStore.saveItem(upcomingDeliveryNoteStoreKey, ids)
    
    // Refresh result from cache so UI is updated once we got back on list page.
    await context.dispatch('fetchUpcomingDeliveryNotes', {
      allowOnlyStore: true
    })
  }
  
  private async updateCommentsAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    comments: string
  ): Promise<DeliveryNoteBean> {
    const deliveryNote = context.getters.currentDeliveryNote?.item()
    const id = deliveryNote?.id
    const deliveryNoteApi = this.getDeliveryNoteApi(context)
    
    const result = await deliveryNoteApi.updateComments(id, comments)
    if (result.pending) {
      await context.dispatch(
        'message/setMessage',
        {message: translateMessage(this.i18n, 'confirm.pending')},
        {root: true}
      )
    }
    if (result.content !== undefined) {
      this.deliveryNoteStore.saveItem(deliveryNote.id, result.content)
      return result.content
    } else {
      return deliveryNote
    }
  }
  
  // noinspection JSUnusedLocalSymbols
  private async fetchDeliveryNoteByCodeAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    siteManagerCode: string
  ): Promise<DeliveryNoteBean> {
    const deliveryNote = await this.siteManagerDeliveryNoteApi.fetchDeliveryNoteByCode(siteManagerCode)
    if (!deliveryNote) {
      throw makeException(this.i18n, 'auth.noDeliveryNoteAssociatedToCode')
    }
    return deliveryNote
  }
  
  // noinspection JSUnusedLocalSymbols
  private async deleteDeliveryNoteAction(
    context: ActionContext<DeliveryNoteState, AppState>,
    id: string
  ): Promise<void> {
    await this.siteManagerDeliveryNoteApi.deleteDeliveryNote(id)
  }
  
  private clearDeliveryNoteModuleAction(context: ActionContext<DeliveryNoteState, AppState>) {
    context.commit('setUpcomingDeliveryNotesOperation', undefined)
    context.commit('setUpcomingDeliveryNotes', undefined)
    context.commit('setDeliveredDeliveryNotes', undefined)
    context.commit('setCurrentDeliveryNoteOperation', undefined)
    context.commit('setCurrentDeliveryNote', undefined)
    this.deliveryNoteStore.clear()
    this.deliveryNoteListStore.clear()
  }
}
