import {AbstractApi} from '@/service/api/AbstractApi'
import {I18n} from 'vue-i18n'
import {deliveryNoteArraySchema, DeliveryNoteBean, deliveryNoteSchema} from '@/service/model/DeliveryNoteBean'
import {LocationBean} from '@/service/model/LocationBean'
import {DeliveryNoteApi} from '@/service/api/DeliveryNoteApi'
import {AuthenticationService} from '@/service/authentication/AuthenticationService'
import {SynchronizationService, SynchronizeResult} from '@/service/SynchronizationService'
import {ConfirmFlow} from '@/stores/modules/DeliveryNoteConfirmModule'

export interface ConfirmLoadBean {
  date: string // ex: 2023-09-27T10:16:11+02:00
  net_weight?: number
  truck_registration?: string
  location?: LocationBean
  comment?: string
}

export interface ConfirmUnloadBean {
  date: string // ex: 2023-09-27T10:16:11+02:00
  location?: LocationBean
  comment?: string
}

export interface ConfirmUnloadBeanWithSiteManagerCode extends ConfirmUnloadBean {
  code: string
}

export class DriverDeliveryNoteApi extends AbstractApi
  implements DeliveryNoteApi {
  
  constructor(
    i18n: I18n,
    authenticationService: AuthenticationService,
    private readonly synchronizationService: SynchronizationService
  ) {
    super(i18n, authenticationService)
  }
  
  fetchDeliveryNote(id: string): Promise<DeliveryNoteBean | null> {
    return this.get<DeliveryNoteBean>({
      path: `/api/mp/v1/driver/delivery-notes/${id}`,
      authentication: 'required',
      schema: deliveryNoteSchema
    })
  }
  
  fetchUpcomingDeliveryNotes(): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/driver/delivery-notes',
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }
  
  fetchDeliveredDeliveryNotes(): Promise<Array<DeliveryNoteBean>> {
    return this.get<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/driver/delivery-notes/delivered',
      authentication: 'required',
      schema: deliveryNoteArraySchema
    })
  }
  
  attachDeliveryNotes(deliveryOrOrderCode: string): Promise<Array<DeliveryNoteBean>> {
    return this.put<Array<DeliveryNoteBean>>({
      path: '/api/mp/v1/driver/delivery-notes/binding-code',
      authentication: 'required',
      schema: deliveryNoteArraySchema,
      body: {
        code: deliveryOrOrderCode
      }
    })
  }
  
  confirmArrival(id: string): Promise<void> {
    return this.postVoid({
      path: `/api/mp/v1/driver/delivery-notes/${id}/confirm-arrival`,
      authentication: 'required'
    })
  }
  
  confirmLoad(
    confirmFlow: ConfirmFlow,
    id: string,
    body: ConfirmLoadBean
  ): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/driver/delivery-notes/${id}/confirm-load`
    return this.synchronizationService.callOrSynchronize(
      {
        type: confirmFlow,
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: body,
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: body
        })
      }
    )
  }
  
  confirmUnloadWithPicture(
    confirmFlow: ConfirmFlow,
    id: string,
    body: ConfirmUnloadBean
  ): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/driver/delivery-notes/${id}/confirm-unload-with-picture`
    return this.synchronizationService.callOrSynchronize(
      {
        type: confirmFlow,
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: body,
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: body
        })
      }
    )
  }
  
  confirmUnloadWithSiteManagerCode(
    confirmFlow: ConfirmFlow,
    id: string,
    body: ConfirmUnloadBeanWithSiteManagerCode
  ): Promise<DeliveryNoteBean> {
    const path = `/api/mp/v1/driver/delivery-notes/${id}/confirm-unload-with-site-manager-code`
    return this.put<DeliveryNoteBean>({
      path: path,
      authentication: 'required',
      schema: deliveryNoteSchema,
      body: body
    })
  }
  
  updateComments(id: string, comments: string): Promise<SynchronizeResult<DeliveryNoteBean>> {
    const path = `/api/mp/v1/driver/delivery-notes/${id}/comment`
    return this.synchronizationService.callOrSynchronize(
      {
        type: 'COMMENT',
        id: id,
        method: 'PUT',
        path: path,
        jsonContent: {comment: comments},
        expectContent: true,
        schema: deliveryNoteSchema
      },
      () => {
        return this.put<DeliveryNoteBean>({
          path: path,
          authentication: 'required',
          schema: deliveryNoteSchema,
          body: {comment: comments}
        })
      }
    )
  }
  
  // TODO: implement
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchSimilarDeliveryNotes(deliveryNoteId: string): Promise<Array<DeliveryNoteBean>> {
    return Promise.resolve([])
  }
  
}
